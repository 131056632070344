import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';

import debounce from 'lodash/debounce';
import filter from 'lodash/filter';
import isEmpty from 'lodash/isEmpty';

import IconButton from 'material-ui/IconButton';
import FlatButton from 'material-ui/FlatButton';
import RaisedButton from 'material-ui/RaisedButton';

import Form from '../../../components/Form';
import ContactModel from '../ContactModel';

import { modifyContact } from '../ContactActions';
import { colours, icons } from '../../../theme';
import './ContactCard.css';

class ContactCard extends Component {
  constructor(props) {
    super(props);
    this.state = { editMode: false, contact: props.contact, redirect: '' };
    this.onChange = debounce(this.onChange.bind(this), 800);
  }
  UNSAFE_componentWillReceiveProps(props) {
    this.setState({ contact: props.contact });
  }
  onChange(val) {
    const { contact } = this.state;
    const key = Object.keys(val).join();
    const prevVal = { [key]: contact[key] };
    this.props.dispatch(
      modifyContact(contact._id, val, contact.userType, prevVal)
    );
  }
  renderEditMode() {
    const { contact } = this.state;
    if (!contact) return null;
    let model = ContactModel;
    if (this.props.staff) {
      const excludedFields = [
        'givenName',
        'surname',
        'email',
        'mobile',
        'phone',
        'postalAddress',
      ];
      model = filter(model, m => excludedFields.indexOf(m.field) === -1);
    }
    return (
      <div>
        <h3>Edit Contact Details</h3>
        <Form
          model={model}
          edit
          fullForm
          object={contact}
          onUpdated={val => this.onChange(val)}
          youngPerson={!!contact.youngPerson}
          carer={!!contact.carer}
        />
      </div>
    );
  }
  renderReadOnly() {
    const { contact } = this.state;
    /* const age = contact.birthDate ? moment().diff(contact.birthDate, 'years') : 999;
    {age < 20 ? ` (age ${age})` : ''} */
    const { residentialAddress, postalAddress } = contact;
    return (
      <div>
        <h3>
          {contact.givenName} {contact.aka ? `"${contact.aka}" ` : ''}
          {contact.surname}
        </h3>
        <div className="contact-card-read-only">
          <b>{contact.gender}</b> -{' '}
          <b>
            {contact.birthDate
              ? moment(contact.birthDate).format('D MMMM YYYY')
              : ''}
          </b>
        </div>
        <div className="contact-card-read-only">
          <b>Email</b>: <a href={`mailto:${contact.email}`}>{contact.email}</a>
        </div>
        <div className="contact-card-read-only">
          <b>Phone</b>: {contact.phone}
        </div>
        <div className="contact-card-read-only">
          <b>Mobile</b>: {contact.mobile}
        </div>
        {!isEmpty(residentialAddress) && (
          <div className="contact-card-address-read-only">
            <h5>Residential Address</h5>
            <div className="contact-card-read-only">
              {residentialAddress.searchText}
            </div>
            <div className="contact-card-read-only">
              {residentialAddress.subpremise
                ? `${residentialAddress.subpremise}/`
                : ''}
              {residentialAddress.street_number} {residentialAddress.route}
            </div>
            <div className="contact-card-read-only">
              {residentialAddress.locality}{' '}
              {residentialAddress.administrative_area_level_1}
            </div>
            <div className="contact-card-read-only">
              {residentialAddress.country} {residentialAddress.postal_code}
            </div>
          </div>
        )}
        {!isEmpty(postalAddress) && (
          <div className="contact-card-address-read-only">
            <h5>Postal Address</h5>
            <div className="contact-card-read-only">
              {postalAddress.searchText}
            </div>
            <div className="contact-card-read-only">
              {postalAddress.subpremise ? `${postalAddress.subpremise}/` : ''}
              {postalAddress.street_number} {postalAddress.route}
            </div>
            <div className="contact-card-read-only">
              {postalAddress.locality}{' '}
              {postalAddress.administrative_area_level_1}
            </div>
            <div className="contact-card-read-only">
              {postalAddress.country} {postalAddress.postal_code}
            </div>
          </div>
        )}
      </div>
    );
  }
  render() {
    const { editMode, contact, redirect } = this.state;
    if (!contact) return null;
    if (redirect) return redirect;
    const { readOnly, redirectTo, select } = this.props;
    const contactCardMenu = (
      <div
        className={`contact-card-menu ${
          editMode ? 'contact-card-menu-visible' : ''
        }`}
      >
        <IconButton>
          {editMode ? (
            <icons.CancelIcon
              color={colours.kRed}
              onClick={() => this.setState({ editMode: !editMode })}
            />
          ) : (
            <icons.EditIcon
              color={colours.kGrey}
              onClick={() => this.setState({ editMode: !editMode })}
            />
          )}
        </IconButton>
      </div>
    );
    return (
      <div className="contact-card">
        {!readOnly && contactCardMenu}
        {readOnly &&
          select && (
            <RaisedButton
              label="Select"
              primary
              className="contact-card-menu contact-card-menu-visible"
              icon={<icons.SelectedIcon />}
              onClick={() => {
                select();
              }}
            />
          )}
        {readOnly &&
          redirectTo && (
            <FlatButton
              label="Go to Profile"
              icon={<icons.ExitToAppIcon />}
              className="contact-card-menu contact-card-menu-visible"
              onClick={() => {
                this.setState({ redirect: <Redirect to={redirectTo} /> });
              }}
            />
          )}
        {editMode ? this.renderEditMode() : this.renderReadOnly()}
      </div>
    );
  }
}

ContactCard.propTypes = {
  dispatch: PropTypes.func.isRequired,
};

const mapStateToProps = () => ({});

export default connect(mapStateToProps)(ContactCard);
